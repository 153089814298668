import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Table,
} from "@/components/ui/table";
import { Card } from "flowbite-react";
import {
  Banknote,
  HandCoins,
  Hourglass,
  ListFilter,
  Ticket,
  Wallet,
} from "lucide-react";
import { StatsCard } from "pages/components";
import React, { useEffect, useState } from "react";
import RequestPayout from "./components/RequestPayout";
import ViewPayouts from "./components/ViewPayouts";
import ViewOrder from "./components/ViewOrder";
import { useSelector } from "react-redux";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useUIMisc from "hooks/useUIMisc";
import { SkeletonCard, SkeletonLine } from "components/custom/SkeletonCards";
import { EmptyContainer } from "components/custom/EmptyState";
import { uniqBy } from "lodash";
import { cleanDate, getCurrencyByCountryName } from "services/misc";
import PaginationComp from "components/custom/PaginationComp";

type OrderProps = {
  order: any;
  openFunc: (type: string, data: any) => void;
  currency: any;
};

const OrderCard = ({ order, openFunc, currency }: OrderProps) => {
  return (
    <TableRow
      className="cursor-pointer"
      key={order._id}
      onClick={() => openFunc("viewOrder", order)}
    >
      <TableCell>
        <div className="font-medium">{order.userData.username}</div>
        <div className="hidden text-sm text-muted-foreground md:inline">
          {order.userData.email}
        </div>
      </TableCell>
      <TableCell>Sale</TableCell>
      <TableCell>
        <Badge className="text-xs" variant="secondary">
          Fulfilled
        </Badge>
      </TableCell>
      <TableCell>{cleanDate(order.createdAt)}</TableCell>
      <TableCell className="text-right">
        {currency}
        {order.subTotal}
      </TableCell>
    </TableRow>
  );
};

const PayoutCard = ({ pay, openDialog }: any) => {
  return (
    <div
      className="flex items-center gap-4 border-b pb-2 mb-1"
      key={pay._id}
      onClick={() => openDialog("viewPayout", pay)}
    >
      <div className="grid gap-1">
        <p className="text-sm font-medium leading-none">
          PayoutID: <span className="uppercase">#{pay.payId}</span>
        </p>
        <p className="text-sm text-muted-foreground">
          {cleanDate(pay.payDate) || "Processing..."}
        </p>
        <div>
          <Badge
            className={`text-white bg-emerald-500 font-normal hover:bg-black hover:text-white capitalize ${
              pay.status === "pending"
                ? "bg-yellow-300 text-black"
                : pay.status === "fulfilled"
                ? " bg-emerald-500"
                : "bg-red-600"
            }`}
          >
            {pay.status}
          </Badge>
        </div>
      </div>
      <div className="ml-auto font-medium">${pay.amount}</div>
    </div>
  );
};

const TransactionsPage = () => {
  const [openModal, setOpenModal] = useState(false);
  const { accountData, dashData } = useSelector(
    (state: any) => state.dashboard
  );
  const [allOrders, setAllOrders] = useState<any[]>([]);
  const [allPayouts, setAllPayouts] = useState<any[]>([]);
  const [selectedPayout, setSelectedPayout] = useState<any>({});
  const [activeModal, setActiveModal] = useState("");
  const [emptyState, setEmptyState] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<any>({});
  const { showLoading, showToast, closeLoading } = useUIMisc();
  const axiosPrivate = useAxiosPrivate();
  const initPageData = {
    page: 1,
    total: 0,
  };
  const initPayPageData = {
    payPage: 1,
    payTotal: 0,
  };
  const initStats = {
    totalRevenue: 0,
    totalPayouts: 0,
    totalPaid: 0,
    totalPending: 0,
  };
  const [statsData, setStatsData]: any = useState(initStats);
  const [pageData, setPageData] = useState(initPageData);
  const [pagePayData, setPayPageData] = useState(initPayPageData);

  const getOrders = async () => {
    try {
      const orders = await axiosPrivate.get(`/orders/${dashData._id}`, {
        params: {
          page: pageData.page,
          sort: "desc",
        },
      });
      const responseData = orders.data.data;
      setPageData({ ...pageData, total: orders.data.total });
      setAllOrders([...allOrders, ...responseData]);
      if (responseData.length === 0 && allOrders.length === 0) {
        setEmptyState(true);
      }
    } catch (error: any) {}
  };

  const addPayouts = async (data: any) => {
    const allData = [data, ...allPayouts];
    setStatsData({
      ...statsData,
      totalPending: statsData.totalPending + 1,
    });
    setAllPayouts(allData);
    setPayPageData({ ...pagePayData, payTotal: pageData.total + 1 });
    setOpenModal(false);
  };

  const getPayouts = async () => {
    try {
      const payouts = await axiosPrivate.get(`/payouts/${dashData._id}`, {
        params: {
          page: pagePayData.payPage,
          sort: "desc",
        },
      });
      const responseData = payouts.data.data;
      setPayPageData({ ...pagePayData, payTotal: payouts.data.total });
      setAllPayouts([...allPayouts, ...responseData]);
      if (responseData.length === 0 && allPayouts.length === 0) {
        setEmptyState(true);
      }
    } catch (error: any) {}
  };

  const openDialog = (type: string, data?: any) => {
    setActiveModal(type);
    setOpenModal(true);
    if (data !== undefined && type === "viewOrder") {
      setSelectedOrder(data);
    } else if (data !== undefined && type === "viewPayout") {
      setSelectedPayout(data);
    }
  };

  const getStats = async () => {
    try {
      const statsRes = await axiosPrivate.get(`/payouts/stats/${dashData._id}`);
      setStatsData(statsRes.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    getStats();
  }, []);

  useEffect(() => {
    getOrders();
  }, [pageData.page]);

  useEffect(() => {
    getPayouts();
  }, [pagePayData.payPage]);

  return (
    <div className="grow">
      <Dialog open={openModal} onOpenChange={setOpenModal}>
        <DialogContent className="max-w-3xl max-h-screen">
          {activeModal === "viewOrder" ? (
            <ViewOrder
              currency={getCurrencyByCountryName(
                accountData.business_type === "event_organizer"
                  ? dashData.venue.country
                  : dashData.country
              )}
              order={selectedOrder}
            />
          ) : activeModal === "viewPayout" ? (
            <ViewPayouts
              currency={getCurrencyByCountryName(
                accountData.business_type === "event_organizer"
                  ? dashData.venue.country
                  : dashData.country
              )}
              payout={selectedPayout}
            />
          ) : activeModal === "requestPayout" ? (
            <RequestPayout
              balance={statsData.totalRevenue - statsData.totalAmountPaid}
              addFunc={addPayouts}
            />
          ) : null}
        </DialogContent>
      </Dialog>

      <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-4">
        <StatsCard
          name="Total Revenue"
          figure={statsData.totalRevenue || 0}
          iconData={{ icon: Wallet }}
          info="Generate from sales"
          currency={getCurrencyByCountryName(
            accountData.business_type === "event_organizer"
              ? dashData.venue.country
              : dashData.country
          )}
        />
        <StatsCard
          name="Total Amount Paid"
          figure={statsData.totalAmountPaid || 0}
          iconData={{ icon: Banknote }}
          info="Amount paid to account"
          currency={getCurrencyByCountryName(
            accountData.business_type === "event_organizer"
              ? dashData.venue.country
              : dashData.country
          )}
        />
        <StatsCard
          name="Total Fulfilled Payouts"
          figure={statsData.totalSoldTickets || 0}
          iconData={{ icon: HandCoins }}
          info="Number of fuifilled payouts"
        />
        <StatsCard
          name="Total Pending Payouts "
          figure={statsData.totalPending || 0}
          iconData={{ icon: Hourglass }}
          info="Number of pending payouts"
        />
      </div>
      <div className="md:flex justify-between items-center mb-5">
        <h5 className="font-bold text-3xl">Transactions</h5>
        <div className="flex justify-end item-center mt-4 md:mt-0 gap-3">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline">
                <ListFilter />
                {""} Filter Payouts
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56">
              <DropdownMenuItem>Live</DropdownMenuItem>
              <DropdownMenuItem>Scanning</DropdownMenuItem>
              <DropdownMenuItem>Both</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <Button
            className="bg-black"
            onClick={() => openDialog("requestPayout")}
          >
            Request Payout
          </Button>
        </div>
      </div>
      <div className="md:grid flex-1 items-start gap-4 sm:py-0 md:gap-8 lg:grid-cols-3 xl:grid-cols-3">
        <div className="md:grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-2 mb-5 md:mb-0">
          <Card x-chunk="dashboard-05-chunk-3">
            <CardContent className="p-0">
              <Table className="table-auto overflow-x-auto">
                <TableHeader className="bg-accent">
                  <TableRow>
                    <TableHead>Customer</TableHead>
                    <TableHead>Type</TableHead>
                    <TableHead>Status</TableHead>
                    <TableHead>Date</TableHead>
                    <TableHead className="text-right">Amount</TableHead>
                  </TableRow>
                </TableHeader>
                {allOrders !== undefined && allOrders.length !== 0 ? (
                  <TableBody className="max-h-[40vh]">
                    <>
                      {uniqBy(allOrders, "_id").map((order) => (
                        <OrderCard
                          currency={getCurrencyByCountryName(
                            accountData.business_type === "event_organizer"
                              ? dashData.venue.country
                              : dashData.country
                          )}
                          order={order}
                          openFunc={openDialog}
                        />
                      ))}

                      <TableRow>
                        <TableCell colSpan={5}>
                          <PaginationComp
                            currentPage={pageData.page}
                            total={pageData.total}
                            docCount={allOrders.length}
                            setPageData={setPageData}
                            pageName="page"
                            pageData={pageData}
                          />
                        </TableCell>
                      </TableRow>
                    </>
                  </TableBody>
                ) : allOrders.length === 0 && !emptyState ? (
                  <>
                    <TableRow>
                      <TableCell colSpan={5}>
                        {Array.from({ length: 1 }).map((card) => (
                          <SkeletonLine />
                        ))}
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <div className="col-span-3">
                        <EmptyContainer
                          message={"No orders yet!"}
                          instructions={`Orders will appear here!`}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </Table>
            </CardContent>
          </Card>
        </div>
        <div>
          <Card x-chunk="dashboard-01-chunk-5">
            <CardHeader className="p-0">
              <CardTitle>Recent Payout</CardTitle>
            </CardHeader>
            <CardContent className="gap-3 p-0">
              {allPayouts !== undefined && allPayouts.length !== 0 ? (
                <>
                  {uniqBy(allPayouts, "_id").map((payout) => (
                    <>
                      <PayoutCard pay={payout} openDialog={openDialog} />
                    </>
                  ))}
                  <div className="my-3 w-full col-span-2">
                    <PaginationComp
                      currentPage={pagePayData.payPage}
                      total={pagePayData.payTotal}
                      docCount={allPayouts.length}
                      setPageData={setPayPageData}
                      pageName="payPage"
                      pageData={pagePayData}
                    />
                  </div>
                </>
              ) : allPayouts.length === 0 && !emptyState ? (
                <>
                  {Array.from({ length: 4 }).map((card) => (
                    <SkeletonCard />
                  ))}
                </>
              ) : (
                <div className="col-span-3">
                  <EmptyContainer
                    message={"No payouts yet!"}
                    instructions={`Click the "Request Payout" button to create one`}
                  />
                </div>
              )}
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default TransactionsPage;
