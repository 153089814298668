import { Badge } from "@/components/ui/badge";
import { ppholder } from "constants/assets";

import { Info, MapPin } from "lucide-react";
import { dateFormatter } from "services/misc";

const DEventCard = ({ data, viewFunc }: any) => {
  return (
    <div
      className="relative h-[400px] w-full cursor-pointer"
      onClick={() => viewFunc(data)}
    >
      <img
        src={data.image !== null ? data.image.url : ppholder}
        alt=""
        className="w-full h-full object-cover rounded-2xl"
      />
      <div className="absolute bottom-0 w-full h-36 p-3 pb-5">
        <div className="w-full p-4 h-full bg-black rounded-2xl flex justify-between">
          <div className="w-full">
            <h3 className="font-medium text-white text-xl capitalize tracking-tighter">
              {data.name}
            </h3>
            <div className="flex justify-start items-center text-gray-400 tracking-tighter capitalize">
              {data.venue.venue_name !== undefined ? (
                <MapPin size={15} />
              ) : (
                <Info size={15} />
              )}{" "}
              <p>
                {data.venue.venue_name !== undefined
                  ? `${data.venue.venue_name}, ${data.venue.city}`
                  : " Edit event with required info."}
              </p>
            </div>
            <Badge
              className={`tracking-tighter ${
                data.master_status === "pending"
                  ? "bg-yellow-300 text-black"
                  : data.master_status === "active"
                  ? " bg-emerald-600"
                  : "bg-red-600"
              }`}
            >
              {data.master_status === "pending"
                ? "Pending Approval"
                : data.master_status === "active"
                ? "Live"
                : data.master_status === "ended"
                ? "Ended"
                : "Rejected"}
            </Badge>
          </div>
          <div className="bg-white w-20 h-20 flex flex-col justify-center items-center rounded-md">
            <span className="text-black text-lg capitalize">
              {dateFormatter(data.startDate, "month")}
            </span>
            <h4 className="font-bold text-black text-3xl">
              {dateFormatter(data.startDate, "day")}
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DEventCard;
