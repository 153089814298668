/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import { Switch } from "@/components/ui/switch";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectGroup,
  SelectItem,
} from "@/components/ui/select";
import dayjs from "dayjs";
import { Separator } from "@/components/ui/separator";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { WHourPickerInterface, dayType } from "types/mainTypes";
import { Label } from "@/components/ui/label";

const WHourPicker = ({
  checked,
  day,
  allDays,
  setDayFunc,
}: WHourPickerInterface) => {
  const initValues: dayType = {
    day: day,
    start_time: "",
    end_time: "",
    active: false,
    time_type: "",
  };
  const [mainTime, setMainTime] = useState<any>({
    start_time: "",
    end_time: "",
  });
  const [timeType, setTimeType] = useState("specify");
  const [initDay, setInitDay]: any = useState<dayType>(initValues);

  const setTime = (type: string, time: string) => {
    setInitDay({ ...initDay, [type]: time });
    updateDays(type, time);
  };

  const updateDays = (field: string, value: string | boolean) => {
    const mainDays: any = [...allDays];
    const dayIndex: any = mainDays.findIndex(
      (mdays: dayType) => mdays.day === day
    );
    if (dayIndex === -1) {
      setDayFunc([
        ...mainDays,
        { ...initDay, time_type: timeType, ...mainTime, [field]: value },
      ]);
    } else {
      mainDays[dayIndex][field] = value;
      setDayFunc(mainDays);
    }
  };

  const toggleSwitch = (e: any) => {
    setInitDay({ ...initDay, active: !initDay.active });
    updateDays("active", e);
  };

  const timeTypeChange = (e: any) => {
    setTimeType(e);
    setInitDay({ ...initDay, time_type: e });
    updateDays("time_type", e);
    if (e === "specify") {
      setTime("start_time", dayjs(mainTime.start_time).format("HH:mm"));
      setTime("end_time", dayjs(mainTime.end_time).format("HH:mm"));
    }
  };

  useEffect(() => {
    const mainDays = [...allDays];
    const filterDays: any = mainDays.find(
      (mdays: dayType) => mdays.day === day
    );
    setInitDay(filterDays === undefined ? initValues : filterDays);
    setTimeType(filterDays === undefined ? "" : filterDays.time_type);
    if (filterDays !== undefined) {
      const mDate = dayjs(Date.now()).format("YYYY-MM-DD");
      const startTime = filterDays.start_time.replace(" ", "");
      const endTime = filterDays.end_time.replace(" ", "");
      setMainTime({
        start_time:
          filterDays !== undefined
            ? dayjs(`${mDate} ${startTime}`, "HH:mm").toDate()
            : dayjs(Date.now(), "HH:mm").toDate(),
        end_time:
          filterDays !== undefined
            ? dayjs(`${mDate} ${endTime}`, "HH:mm").toDate()
            : dayjs(Date.now(), "HH:mm").toDate(),
      });
    }
  }, []);

  return (
    <div className="border p-4 rounded-lg mb-4">
      <div className="flex justify-between items-center">
        <h6 className="text-md font-normal capitalize">{day}</h6>
        <div>
          <div className="flex items-center space-x-2">
            <Switch
              id="status_toggle"
              checked={initDay.active !== undefined && initDay.active}
              onCheckedChange={toggleSwitch}
            />
            <Label htmlFor="status_toggle">
              {initDay.active ? "Active" : "Not Active"}
            </Label>
          </div>
        </div>
      </div>
      <Separator className="my-2" />
      {/* <div>
        <Label htmlFor="type">Type</Label>
        <Select value={timeType} onValueChange={timeTypeChange}>
          <SelectTrigger className="w-full focus:ring-0 mt-1" id="type">
            <SelectValue
              className="focus:ring-0 font-normal"
              placeholder="Select type"
            />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectItem value="specify">Specify times</SelectItem>
              <SelectItem value="allday">Open 24 hours</SelectItem>
              <SelectItem value="closed">Closed</SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      </div> */}
      {/* {timeType === "specify" ? ( */}
      <div className="grid grid-cols-2 gap-4 mt-2">
        <div className="space-y-1">
          <Label htmlFor="opentime">Open time</Label>
          <DatePicker
            selected={mainTime.start_time}
            onChange={(date) => {
              setMainTime({ ...mainTime, start_time: date });
              setTime("start_time", dayjs(date).format("HH:mm"));
            }}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={30}
            className="focus-visible:outline-0 w-full focus:outline-none text-md focus-visible:ring-0 text-sm font-light resize-none  rounded-md border-slate-200"
            timeCaption="Time"
            dateFormat="h:mm"
          />
        </div>
        <div className="space-y-1">
          <Label htmlFor="closetime">Close time</Label>
          <DatePicker
            selected={mainTime.end_time}
            onChange={(date) => {
              setMainTime({ ...mainTime, end_time: date });
              setTime("end_time", dayjs(date).format("HH:mm"));
            }}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={30}
            className="focus-visible:outline-0 w-full focus:outline-none text-md focus-visible:ring-0 text-sm font-light resize-none rounded-md border-slate-200"
            timeCaption="Time"
            dateFormat="h:mm"
          />
        </div>
      </div>
      {/* ) : (
        <></>
      )} */}
    </div>
  );
};

export default WHourPicker;
