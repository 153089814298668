import { StatsCard } from "pages/components";

import FeedCard from "./component/FeedCard";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../@/components/ui/dropdown-menu";

import { Drama, Heart, ListFilter, MessageSquare } from "lucide-react";
import { Button } from "../../../@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../@/components/ui/dialog";
import { useEffect, useState } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useUIMisc from "hooks/useUIMisc";
import { useSelector } from "react-redux";
import { uniqBy } from "lodash";
import { SkeletonCard } from "components/custom/SkeletonCards";
import { EmptyContainer } from "components/custom/EmptyState";
import { DEFAULT_FILTER } from "constants/data";
import { isObjEmpty } from "services/misc";
import useScrollToBottom from "hooks/useScrollToBottom";
import StoryView from "./component/StoryView";
type Props = {};

const FeedsPage = (props: Props) => {
  const axiosPrivate = useAxiosPrivate();
  const initPageData = {
    page: 1,
    total: 0,
  };
  const [openModal, setOpenModal] = useState(false);
  const [activeModal, setActiveModal] = useState("");
  const initFilter = {
    sort: "desc",
  };
  const initStats = {
    totalFeeds: 0,
    totalLikes: 0,
    totalComments: 0,
  };
  const [statsData, setStatsData]: any = useState(initStats);
  const [filter, setFilter] = useState(initFilter);
  const [allFeeds, setAllFeeds] = useState<any[]>([]);
  const { dashData } = useSelector((state: any) => state.dashboard);
  const [emptyState, setEmptyState] = useState(false);
  const [pageData, setPageData] = useState(initPageData);
  const [selecteFeed, setSelecteFeed] = useState<any>({});
  const { showLoading, showToast, closeLoading } = useUIMisc();

  const getFeeds = async () => {
    try {
      const feeds = await axiosPrivate.get(`/feeds/event/${dashData._id}`, {
        params: {
          page: pageData.page,
          sort: filter.sort,
        },
      });
      const responseData = feeds.data.data;
      setPageData({ ...pageData, total: feeds.data.total });
      setAllFeeds([...allFeeds, ...responseData]);
      if (responseData.length === 0 && allFeeds.length === 0) {
        setEmptyState(true);
      }
    } catch (error: any) {
      showToast(error.response.data.message, "error");
    }
  };

  const getFeedStats = async () => {
    try {
      const feeds = await axiosPrivate.get(`/feeds/stats/${dashData._id}`);
      const responseData = feeds.data;
      setStatsData(
        !isObjEmpty(responseData.data) ? responseData.data : initStats
      );
    } catch (error: any) {
      showToast(error.response.data.message, "error");
    }
  };

  const changeFilter = (filterType: string) => {
    setAllFeeds([]);
    setPageData(initPageData);
    setFilter({ ...filter, sort: filterType });
  };

  const updateFeeds = (postId: string) => {
    const mainFeeds = [...allFeeds];
    setStatsData({ ...statsData, totalFeeds: statsData.totalFeeds - 1 });
    const findFeedIndex = mainFeeds.findIndex((a) => a._id === postId);
    mainFeeds.splice(findFeedIndex, 1);
    setAllFeeds([...mainFeeds]);
  };

  useEffect(() => {
    getFeeds();
  }, [pageData.page, filter.sort]);

  useEffect(() => {
    getFeedStats();
  }, []);

  const viewPost = (data: any) => {
    setActiveModal("feedview");
    setSelecteFeed(data);
    setOpenModal(true);
  };

  const viewStory = () => {
    setActiveModal("story");
    setOpenModal(true);
  };

  const deletePost = async (postId: string) => {
    setOpenModal(false);
    try {
      await axiosPrivate.delete(`/feeds/${postId}/organizer`);
      updateFeeds(postId);
      showToast("Post deleted successfully!", "success");
    } catch (error) {
      showToast("An error occured!", "danger");
    }
  };

  const loadMore = () => {
    if (pageData.total !== allFeeds.length) {
      setPageData({ ...pageData, page: pageData.page + 1 });
    }
  };

  const loadMoreData = () => {
    setTimeout(() => {
      loadMore();
      resetFetching();
    }, 1000);
  };

  const { resetFetching } = useScrollToBottom(loadMoreData, 100);

  return (
    <div>
      <Dialog open={openModal} onOpenChange={setOpenModal}>
        <DialogContent className="max-w-3xl overflow-y-scroll max-h-screen">
          {activeModal === "feedview" ? (
            <FeedCard
              delFunc={deletePost}
              feed={selecteFeed}
              feedFunc={viewPost}
              postView={true}
            />
          ) : activeModal === "story" ? (
            <>
              <DialogHeader>
                <DialogTitle>Your Stories</DialogTitle>
              </DialogHeader>
              <StoryView />
            </>
          ) : (
            <></>
          )}
        </DialogContent>
      </Dialog>
      <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-4">
        <StatsCard
          name="Total Feeds"
          figure={statsData.totalFeeds}
          iconData={{ icon: MessageSquare }}
          info="Number of feeds"
        />
        <StatsCard
          name="Total Likes"
          figure={statsData.totalLikes}
          iconData={{ icon: Heart }}
          info="Number of likes"
        />
        <StatsCard
          name="Total Comments"
          figure={statsData.totalComments}
          iconData={{ icon: MessageSquare }}
          info="Number of comments"
        />
      </div>
      <div className="flex justify-end mt-4 gap-3">
        {dashData.feed_ability && (
          <Button variant="outline" onClick={viewStory}>
            <Drama /> My Story
          </Button>
        )}
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline">
              <ListFilter /> Filter Posts
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56">
            {DEFAULT_FILTER.map((defFilter) => (
              <DropdownMenuItem onClick={() => changeFilter(defFilter.slug)}>
                {defFilter.name}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div>
        <div className="mt-5">
          {allFeeds !== undefined && allFeeds.length !== 0 ? (
            <div className="columns-1 md:columns-3 lg:columns-3 m-0">
              {uniqBy(allFeeds, "_id").map((feed) => (
                <>
                  <FeedCard
                    feed={feed}
                    feedFunc={viewPost}
                    postView={false}
                    delFunc={deletePost}
                  />
                </>
              ))}
            </div>
          ) : allFeeds.length === 0 && !emptyState ? (
            <div className="columns-2 md:columns-3 lg:columns-3 m-0">
              {Array.from({ length: 4 }).map((card) => (
                <SkeletonCard />
              ))}
            </div>
          ) : (
            <div className="col-span-3">
              <EmptyContainer
                message={"No feeds yet!"}
                instructions={`Feeds will display when one is added!`}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeedsPage;
