import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectGroup,
  Select,
  SelectItem,
} from "@/components/ui/select";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { countries } from "country-list-json";
import * as yup from "yup";
import { Formik } from "formik";
import { formValidations } from "utils/validations/formValidations";
import { mainCountries } from "utils/countries";

interface AddAcc {
  addFunc: (data: any) => void;
}

const AddAccount = ({ addFunc }: AddAcc) => {
  const initAccValues = {
    name: "",
    email: "",
    business_type: "",
    origin: "",
    username: "",
  };

  const accSchema = yup.object().shape({
    email: formValidations.email,
    name: formValidations.main_name,
    business_type: formValidations.default,
    origin: formValidations.default,
    username: formValidations.username,
  });

  const handleSubmit = async (values: any, resetForm: any) => {
    addFunc(values);
    setTimeout(() => {
      resetForm();
    }, 1000);
  };

  return (
    <Card x-chunk="dashboard-05-chunk-3" className="border-none shadow-none">
      <CardHeader className="p-4 mt-6">
        <CardTitle>Add Account</CardTitle>
        <CardDescription>
          Fill the following fields. Click submit to add account.
        </CardDescription>
      </CardHeader>
      <CardContent className="p-4 mt-6">
        <Formik
          validationSchema={accSchema}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values, resetForm);
          }}
          initialValues={initAccValues}
        >
          {({
            values,
            errors,
            handleChange,
            touched,
            setFieldValue,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <div>
                <div className="grid gap-4 mb-4 sm:grid-cols-2">
                  <div className="mb-4">
                    <div className="flex flex-col space-y-1.5">
                      <Label htmlFor="name">Business Name</Label>
                      <Input
                        id="name"
                        type="text"
                        placeholder="OutFun Ent or OutFun Mgmt"
                        required
                        onChange={handleChange}
                        value={values.name}
                      />
                      {errors.name && touched.name ? (
                        <span className="text-sm font-light text-red-500 font-gelion">
                          {errors.name}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="flex flex-col space-y-1.5">
                      <Label htmlFor="username">Business Username</Label>
                      <Input
                        id="username"
                        type="text"
                        placeholder="@outfunent"
                        required
                        onChange={handleChange}
                        value={values.username}
                      />
                      {errors.username && touched.username ? (
                        <span className="text-sm font-light text-red-500 font-gelion">
                          {errors.username}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="mb-4">
                  <div className="flex flex-col space-y-1.5">
                    <Label htmlFor="email">Business Email</Label>
                    <Input
                      id="email"
                      type="email"
                      placeholder="example@outfun.com"
                      onChange={handleChange}
                      value={values.email}
                    />
                    {errors.email && touched.email ? (
                      <span className="text-sm font-light text-red-500 font-gelion">
                        {errors.email}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="grid gap-4 mb-4 sm:grid-cols-2">
                  <div className="flex flex-col space-y-1.5">
                    <Label htmlFor="business_type">Business Type</Label>
                    <Select
                      name="business_type"
                      onValueChange={(e) => {
                        setFieldValue("business_type", e);
                      }}
                      value={values.business_type}
                    >
                      <SelectTrigger
                        className="w-full focus:ring-0 mt-1"
                        id="business_type"
                      >
                        <SelectValue
                          className="focus:ring-0 font-normal"
                          placeholder="Select"
                        />
                      </SelectTrigger>
                      <SelectContent className="h-36">
                        <SelectGroup>
                          <SelectItem value="event_organizer">
                            Event Organizer
                          </SelectItem>
                          <SelectItem value="venue_manager">
                            Venue Manager
                          </SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                    {errors.business_type && touched.business_type ? (
                      <span className="text-sm font-light text-red-500 font-gelion">
                        {errors.business_type}
                      </span>
                    ) : null}
                  </div>
                  <div className="flex flex-col space-y-1.5">
                    <Label htmlFor="origin">Country</Label>
                    <Select
                      name="origin"
                      onValueChange={(e) => {
                        setFieldValue("origin", e);
                      }}
                      value={values.origin}
                    >
                      <SelectTrigger
                        className="w-full focus:ring-0 mt-1"
                        id="origin"
                      >
                        <SelectValue
                          className="focus:ring-0 font-normal"
                          placeholder="Select"
                        />
                      </SelectTrigger>
                      <SelectContent className="h-36">
                        <SelectGroup>
                          {mainCountries.map((country) => (
                            <SelectItem value={country.name} key={country.name}>
                              {country.name} {country.flag}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                    {errors.origin && touched.origin ? (
                      <span className="text-sm font-light text-red-500 font-gelion">
                        {errors.origin}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="text-center mt-7">
                  <Button type="submit" className="bg-black">
                    Submit
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default AddAccount;
