import React from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Table,
} from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";
import { cleanDate } from "services/misc";
import { Repeat, ScanBarcode } from "lucide-react";

type VOrderProps = {
  order: any;
  currency?: any;
};

const ViewOrder = ({ order, currency }: VOrderProps) => {
  return (
    <div className="overflow-x-scroll">
      <Card className="w-full p-0 border-0 shadow-none">
        <CardHeader>
          <CardTitle>View Order</CardTitle>
          <CardDescription>Shows detailed info of order</CardDescription>
        </CardHeader>
        <CardContent className="p-0 md:p-6">
          <div className="border rounded-lg p-2">
            <Table className="table-auto overflow-x-auto">
              <TableHeader className="bg-accent rounded-t-lg">
                <TableRow>
                  <TableHead>Customer</TableHead>
                  <TableHead>Type</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead className="hidden md:table-cell">Date</TableHead>
                  <TableHead className="text-right">Amount</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody className="max-h-[40vh]">
                <TableRow className="cursor-pointer" key={order._id}>
                  <TableCell>
                    <div className="font-medium">{order.userData.username}</div>
                    <div className="hidden text-sm text-muted-foreground md:inline">
                      {order.userData.email}
                    </div>
                  </TableCell>
                  <TableCell>Sale</TableCell>
                  <TableCell>
                    <Badge className="text-xs" variant="secondary">
                      Fulfilled
                    </Badge>
                  </TableCell>
                  <TableCell className="hidden md:table-cell">
                    {cleanDate(order.createdAt)}
                  </TableCell>
                  <TableCell className="text-right">
                    {currency || ""}
                    {order.subTotal}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <div className="mt-4">
            <h5 className="mb-3 text-sm text-muted-foreground">
              Tickets Breakdown
            </h5>
            {order.tickets.map((ticket: any) => (
              <div
                className="flex items-center gap-4 border-b pb-2 mb-1"
                key={ticket._id}
              >
                <div className="grid gap-1">
                  <p className="text-sm font-medium leading-none">
                    {ticket.type}
                  </p>
                  <div className="text-sm text-muted-foreground md:flex justify-start items-center gap-2">
                    <span className="p-1 bg-gray-100 text-sm block md:flex">
                      {ticket.code}
                    </span>
                    <div className="flex items-center">
                      <Badge
                        className={`tracking-tighter ${
                          ticket.status === "active"
                            ? " bg-emerald-600"
                            : "bg-red-600"
                        }`}
                      >
                        {ticket.status === "active" ? "Active" : "Used"}
                      </Badge>
                      <Badge
                        className={`tracking-tighter ${
                          ticket.scanned
                            ? " bg-emerald-600"
                            : "bg-yellow-300 text-black"
                        }`}
                      >
                        <ScanBarcode size={15} />{" "}
                        {ticket.scanned ? "Scanned" : "Not yet"}
                      </Badge>
                      <Badge
                        className={`tracking-tighter ${
                          ticket.shared
                            ? " bg-emerald-600"
                            : "bg-yellow-300 text-black"
                        }`}
                      >
                        <Repeat size={15} />{" "}
                        {ticket.shared ? "Shared" : "Not yet"}
                      </Badge>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="flex justify-end mt-2 gap-1 items-center">
              <span className="text-sm text-muted-foreground">Total:</span>{" "}
              <h5 className="font-bold text-lg">
                {currency || ""}
                {order.subTotal}
              </h5>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default ViewOrder;
