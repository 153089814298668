/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { format } from "date-fns";
import { Label } from "@/components/ui/label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { CalendarIcon } from "lucide-react";
import { Calendar } from "@/components/ui/calendar";
import { Button } from "@/components/ui/button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { CAMPAIGNS } from "constants/data";
import { Formik } from "formik";
import { formValidations } from "utils/validations/formValidations";
import * as yup from "yup";
import useUIMisc from "hooks/useUIMisc";
import { calculateDays } from "services/misc";
import { useSelector } from "react-redux";
import PaystackPop from "@paystack/inline-js";
import useAxiosPrivate from "hooks/useAxiosPrivate";

type Props = {};

const AddCampaign = ({ setOpenModal, addFunc }: any) => {
  const [rate, setRate] = useState<any>();
  const paystack = new PaystackPop();
  const axiosPrivate = useAxiosPrivate();
  const { dashData, accountData } = useSelector(
    (state: any) => state.dashboard
  );
  const { oforg } = useSelector((state: any) => state.auth);
  const [startDate, setStartDate]: any = useState(null);
  const [endDate, setEndDate]: any = useState(null);
  const { showLoading, showToast, closeLoading } = useUIMisc();
  const [selectedCampaign, setSelectedCampaign] =
    useState("Homepage Highlight");
  const [totalPrice, setTotalPrice]: any = useState(0);
  const initTeamValues = {
    name: "",
    start_date: "",
    end_date: "",
  };

  const campaignSchema = yup.object().shape({
    name: formValidations.defaultString,
    start_date: formValidations.datetime,
    end_date: formValidations.datetime,
  });

  const addCampaign = async (values: any, info: any) => {
    const campaignData = {
      campaign_type: CAMPAIGNS.find((a) => a.name === selectedCampaign),
      transaction_info: info,
      reqId: dashData._id,
      owner_type: accountData.business_type,
      charge: parseFloat(totalPrice),
      ...values,
    };

    try {
      const campaignRes = await axiosPrivate.post(`/campaigns`, campaignData);
      const campaign = campaignRes.data.data;
      closeLoading();
      showToast(`Campaign added successful`, "success");
      addFunc(campaign);
    } catch (error: any) {
      closeLoading();
      showToast(`An error occured!\n ${error.message}\n Try again!`, "error");
    }
  };

  const getRates = async () => {
    try {
      const rateRes = await axiosPrivate.get(`/orders/rates`, {
        params: {
          origin: "United States",
        },
      });
      const rate = rateRes.data.data;
      setRate(rate);
    } catch (error: any) {}
  };

  const payCampaign = (values: any) => {
    setOpenModal(false);
    const amount = totalPrice * 100 * rate[0].rate;
    paystack.newTransaction({
      key: process.env.REACT_APP_PS_PUBLIC_LIVE_KEY,
      email: oforg.email,
      amount: amount + amount * 0.0195,
      channels: ["card", "bank", "ussd", "qr", "mobile_money", "bank_transfer"],
      currency: "GHS",
      onCancel: () => {
        showToast("Transaction not completed!", "error");
      },
      onSuccess: (response: any) => {
        addCampaign(values, response);
      },
    });
  };

  const calcPricing = () => {
    const days = calculateDays(startDate, endDate);
    const getPackages: any = CAMPAIGNS.find(
      (a: any) => a.name === selectedCampaign
    );
    if (startDate && endDate) {
      setTotalPrice((getPackages?.fee * days).toFixed(2));
    }
  };

  const handleStartDateSelect = (date: any) => {
    if (date >= new Date()) {
      setStartDate(date);
      setEndDate(null); // Reset the end date if start date is changed
    }
  };

  useEffect(() => {
    getRates();
  }, []);

  useEffect(() => {
    calcPricing();
  }, [selectedCampaign, startDate, endDate]);

  return (
    <div>
      <Card className="w-full p-0 border-0 shadow-none">
        <CardHeader>
          <CardTitle>Add Campaign</CardTitle>
          <CardDescription>
            Fill the following fields. Click submit when you're done.
          </CardDescription>
        </CardHeader>
        <CardContent className="p-2 md:p-6">
          <Formik
            validationSchema={campaignSchema}
            onSubmit={(values) => {
              payCampaign(values);
            }}
            initialValues={initTeamValues}
          >
            {({
              values,
              errors,
              handleChange,
              touched,
              setFieldValue,
              resetForm,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <div className="flex flex-col space-y-1.5">
                    <Label htmlFor="tickets">Name of Campaign</Label>
                    <Input
                      id="tickets"
                      type="text"
                      placeholder="Early Bird"
                      onChange={handleChange}
                      name="name"
                      value={values.name}
                    />
                    {errors.name && touched.name && (
                      <span className="text-sm font-light text-red-500">
                        {errors.name}
                      </span>
                    )}
                  </div>
                </div>
                <div className="grid gap-4 mb-4 sm:grid-cols-2">
                  <div className="flex flex-col space-y-1.5">
                    <Label htmlFor="sendelling">Start Date</Label>
                    <Popover>
                      <PopoverTrigger asChild>
                        <Button
                          variant={"outline"}
                          className={cn(
                            "w-full justify-start text-left font-normal",
                            !startDate && "text-muted-foreground"
                          )}
                        >
                          <CalendarIcon className="mr-2 h-4 w-4" />
                          {startDate ? (
                            format(startDate, "PPP")
                          ) : (
                            <span>Pick a date</span>
                          )}
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <Calendar
                          mode="single"
                          initialFocus
                          // disabled={(date) => date < currentDate}
                          disabled={(date) => date < new Date()} // Disable past dates
                          selected={startDate}
                          onSelect={(e) => {
                            setFieldValue("start_date", e);
                            handleStartDateSelect(e);
                          }}
                        />
                      </PopoverContent>
                    </Popover>
                    {errors.start_date && touched.start_date && (
                      <span className="text-sm font-light text-red-500">
                        {errors.start_date}
                      </span>
                    )}
                  </div>
                  <div className="flex flex-col space-y-1.5">
                    <Label htmlFor="startselling">End Date</Label>
                    <Popover>
                      <PopoverTrigger asChild>
                        <Button
                          variant={"outline"}
                          className={cn(
                            "w-full justify-start text-left font-normal",
                            !endDate && "text-muted-foreground"
                          )}
                        >
                          <CalendarIcon className="mr-2 h-4 w-4" />
                          {endDate ? (
                            format(endDate, "PPP")
                          ) : (
                            <span>Pick a end date</span>
                          )}
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <Calendar
                          mode="single"
                          initialFocus
                          selected={endDate}
                          disabled={(date) => !startDate || date < startDate}
                          onSelect={(e) => {
                            setFieldValue("end_date", e);
                            setEndDate(e);
                          }}
                        />
                      </PopoverContent>
                    </Popover>
                    {errors.end_date && touched.end_date && (
                      <span className="text-sm font-light text-red-500">
                        {errors.end_date}
                      </span>
                    )}
                  </div>
                </div>
                <div>
                  <Label htmlFor="tickets">Campaign Type</Label>
                  <Tabs
                    defaultValue={selectedCampaign}
                    className="w-full "
                    onValueChange={(e) => setSelectedCampaign(e)}
                  >
                    <TabsList className="grid grid-cols-2 md:grid-cols-2">
                      {CAMPAIGNS.map((campaign) => (
                        <TabsTrigger value={campaign.name}>
                          <div className="w-40">
                            <h5>{campaign.name}</h5>
                          </div>
                        </TabsTrigger>
                      ))}
                    </TabsList>
                    {CAMPAIGNS.map((campaign) => (
                      <TabsContent value={campaign.name}>
                        <div className="border border-gray-100 p-3">
                          <h5 className="font-medium text-md">
                            {campaign.price}
                          </h5>
                          <span className="text-gray-400 text-sm">
                            Benefits
                          </span>
                          <ul role="list" className="mb-3">
                            {campaign.benefit.map((benefit, index) => (
                              <li key={index}>
                                <div className="flex justify-start items-center">
                                  <span className="text-sm font-normal ps-2 leading-tight">
                                    {benefit}
                                  </span>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </TabsContent>
                    ))}
                  </Tabs>
                </div>
                <div className="border border-gray-100 p-3">
                  <h5 className="text-md font-semibold">Campaign Summary</h5>
                  <h6 className="text-sm font-normal ps-2 leading-tight">
                    Number of days:{" "}
                    {endDate && startDate !== null
                      ? calculateDays(startDate, endDate)
                      : 0}
                  </h6>
                  <h6 className="text-sm font-normal ps-2 leading-tight">
                    Total Fee: ${totalPrice}
                  </h6>
                </div>

                <div className="text-center mt-5">
                  <Button type="submit" className="bg-black">
                    Submit
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </div>
  );
};

export default AddCampaign;
