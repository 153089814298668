import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Input } from "@/components/ui/input";
import {
  SelectTrigger,
  SelectValue,
  Select as MainSelect,
  SelectContent,
  SelectItem,
  SelectGroup,
} from "@/components/ui/select";
import Select from "react-select";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { Image, ImageIcon, Trash2, Trash2Icon } from "lucide-react";
import { ppholder } from "constants/assets";
import { dayType } from "types/mainTypes";
import {
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Card } from "flowbite-react";
import { Label } from "@/components/ui/label";
import WHourPicker from "pages/dashboard/dashboardsettings/components/components/WHourPicker";
import SettingsMap from "pages/dashboard/dashboardsettings/components/components/SettingsMap";
import { useEffect, useState } from "react";
import { formValidations } from "utils/validations/formValidations";
import * as yup from "yup";
import { Formik } from "formik";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useUIMisc from "hooks/useUIMisc";
import { useDispatch, useSelector } from "react-redux";
import { toArrayList, transformedArray } from "services/misc";
import { setDashboardData } from "redux/features/dashboardSlice";
import { EmptyContainer } from "components/custom/EmptyState";
import { mainCountries } from "utils/countries";
import { AMENITIES, SUITABLE_FOR, VENUE_TYPES } from "constants/data";

type AEProps = {
  viewType: string;
  pageType: string;
  addFunc?: (data: any) => void;
};

const AddEditVenue = ({ viewType, addFunc, pageType }: AEProps) => {
  const { accountData, dashData } = useSelector(
    (state: any) => state.dashboard
  );
  const dispatch = useDispatch();
  const [photos, setPhotos] = useState<any[]>([]);
  const [image, setImage] = useState<any>({
    profile_img: null,
    banner: null,
  });
  const [previewImage, setPreviewImage] = useState<any>({
    profile_img: "",
    banner: "",
  });

  const initAddVenueValue = {
    name: "",
    capacity: 0,
    typeOfVenue: [],
    suitableFor: [],
    amenities: [],
    bio: "",
  };

  const addVenueSchema = yup.object().shape({
    name: formValidations.default,
    capacity: formValidations.defaultNum,
    bio: formValidations.default,
    typeOfVenue: formValidations.defaultArr,
    suitableFor: formValidations.defaultArr,
    amenities: formValidations.defaultArr,
  });

  const initVenueEditValue = {
    name: viewType === "settings" ? dashData.name : "",
    capacity: viewType === "settings" ? dashData.capacity : "",
    bio: viewType === "settings" ? dashData.bio : "",
    typeOfVenue: viewType === "settings" ? dashData.venue_type : "",
    suitableFor: viewType === "settings" ? dashData.suitableFor : "",
    amenities: viewType === "settings" ? dashData.amenities : "",
  };

  const initSocialValues = {
    facebook: viewType === "settings" ? dashData.facebook : "",
    instagram: viewType === "settings" ? dashData.instagram : "",
    twitter: viewType === "settings" ? dashData.twitter : "",
    linkedin: viewType === "settings" ? dashData.linkedin : "",
    tiktok: viewType === "settings" ? dashData.tiktok : "",
    youtube: viewType === "settings" ? dashData.youtube : "",
  };
  const initContactValues = {
    address: viewType === "settings" ? dashData.address : "",
    city: viewType === "settings" ? dashData.city : "",
    country: viewType === "settings" ? dashData.country : "",
    website: viewType === "settings" ? dashData.website : "",
    email: viewType === "settings" ? dashData.email : "",
    phone_one: viewType === "settings" ? dashData.phone_one : "",
    phone_two: viewType === "settings" ? dashData.phone_two : "",
    venue_link: viewType === "settings" ? dashData.venue_link : "",
  };

  const venueSchema = yup.object().shape({
    name: formValidations.default,
    typeOfVenue: formValidations.defaultArr,
    suitableFor: formValidations.defaultArr,
    amenities: formValidations.defaultArr,
    bio: formValidations.default,
    capacity: formValidations.defaultNum,
  });

  const contactSchema = yup.object().shape({
    address: formValidations.address,
    city: formValidations.address,
    website: formValidations.link,
    email: formValidations.email,
    country: formValidations.address,
    phone_one: formValidations.default,
    phone_two: formValidations.default,
    venue_link: formValidations.link_required,
  });

  const socialSchema = yup.object().shape({
    instagram: formValidations.link,
    twitter: formValidations.link,
    tiktok: formValidations.link,
    youtube: formValidations.link,
    facebook: formValidations.link,
    linkedin: formValidations.link,
  });

  const mainSchema = viewType === "add" ? addVenueSchema : venueSchema;
  const initValues =
    viewType === "add" ? initAddVenueValue : initVenueEditValue;
  const axiosPrivate = useAxiosPrivate();
  const { showToast, closeLoading, showLoading } = useUIMisc();
  const [typeOfVenue, setTypeOfVenue] = useState<any[]>(
    dashData !== null ? transformedArray(dashData.venue_type) : []
  );
  const [amenities, setAmenities] = useState<any[]>(
    dashData !== null ? transformedArray(dashData.amenities) : []
  );
  const [suitableFor, setSuitableFor] = useState<any[]>(
    dashData !== null ? transformedArray(dashData.suitableFor) : []
  );
  const [location, setLocation] = useState<any>([
    5.60003365639047, -0.18919420975159973,
  ]);

  const multiSelecClasses = {
    control: () => "!border-slate-200 !py-[.4%] !rounded-md ",
    placeholder: () => "!text-sm !text-muted-foreground",
    multiValueLabel: () => "bg-slate-100",
    multiValueRemove: () => "bg-slate-200",
  };
  const [allDays, setAllDays] = useState<dayType[]>(
    dashData !== null ? dashData.work_days : []
  );

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleSubmit = async (values: any, submitType: string) => {
    showLoading();

    const venueData =
      submitType === "default"
        ? {
            ...values,
            venue_type: toArrayList(typeOfVenue),
            amenities: toArrayList(amenities),
            suitableFor: toArrayList(suitableFor),
            business_type: "venue",
          }
        : submitType === "extra"
        ? {
            ...values,
            business_type: "venue",
          }
        : {};
    try {
      scrollToTop();
      const venueRes =
        viewType === "add"
          ? await axiosPrivate.post(
              `/business/add/${accountData._id}`,
              venueData
            )
          : await axiosPrivate.put(`/business/edit/${dashData._id}`, venueData);
      if (viewType === "add") {
        addFunc?.(venueRes.data.data);
      } else {
        dispatch(setDashboardData(venueRes.data.data));
      }
      setTimeout(() => {
        closeLoading();
        showToast(
          `${
            viewType === "add"
              ? "Venue created successfully!"
              : "Venue updated successfully!"
          }`,
          "success"
        );
      }, 1000);
    } catch (error: any) {
      setTimeout(() => {
        closeLoading();
        showToast(`An error occured!\n ${error.message}\n Try again!`, "error");
      }, 2000);
    }
  };

  const handleImageUpload = async (file: any, type: string) => {
    showLoading();
    let formData = new FormData();
    formData.append("image", file);
    // dispatch(showLoader);
    try {
      await axiosPrivate
        .put(`/business/upload/${dashData._id}/${type}/venue`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          setTimeout(() => {
            closeLoading();
            showToast(
              `${
                type === "banner" ? "Banner" : "Profile"
              } Image updated successfully!`,
              "success"
            );
          }, 1000);
          dispatch(setDashboardData(res.data.data));
        });
    } catch (error: any) {
      setTimeout(() => {
        closeLoading();
        showToast(`An error occured!\n ${error.message}\n Try again!`, "error");
      }, 2000);
      // dispatch(hideLoader);
    }
  };

  const handlePhotoUpload = async (file: any) => {
    let formData = new FormData();
    formData.append("image", file);
    showLoading();
    try {
      const uploadImg = await axiosPrivate.put(
        `/business/photos/${dashData._id}/venue`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      setTimeout(() => {
        closeLoading();
        showToast(`Uploaded Image successfully!`, "success");
      }, 1000);
      setPhotos(uploadImg.data.data.images);
    } catch (error: any) {
      setTimeout(() => {
        closeLoading();
        showToast(`An error occured!\n ${error.message}\n Try again!`, "error");
      }, 2000);
    }
  };

  const delImage = (type: string) => {
    if (type === "profile_img") {
      setPreviewImage({ ...previewImage, profile_img: "" });
      setImage({ ...image, profile_img: null });
    } else if (type === "banner") {
      setPreviewImage({ ...previewImage, banner: "" });
      setImage({ ...image, banner: null });
    }
  };

  const chooseImg = (type: string) => {
    const inputElem = document.createElement("input");
    inputElem.type = "file";
    inputElem.name = "image";
    inputElem.setAttribute("accept", ".jpg, .png, jpeg");
    inputElem.click();
    inputElem.onchange = (ev: any) => {
      if (ev.target.files[0].size > 2097152) {
        showToast(`Image must be 2MB maximum!`, "warning");
      } else {
        if (type === "profile_img") {
          setPreviewImage({
            ...previewImage,
            profile_img: URL.createObjectURL(ev.target.files[0]),
          });
          setImage({ ...image, profile_img: ev.target.files[0] });
          handleImageUpload(ev.target.files[0], type);
        } else if (type === "photos") {
          handlePhotoUpload(ev.target.files[0]);
        }
      }
    };
  };

  const getPhotos = async () => {
    try {
      const photoRes = await axiosPrivate.get(
        `/business/photos/${dashData._id}`
      );
      if (photoRes.data.data.images !== undefined) {
        setPhotos(photoRes.data.data.images);
      }
    } catch (error: any) {
      // closeLoader();
      showToast(`${error.response.data.message}`, "error");
    }
  };

  const delPhotos = async (index: number) => {
    showLoading();
    const allImgs = [...photos];
    try {
      await axiosPrivate.delete(
        `/business/photos/${dashData._id}/${index}/venue`
      );
      allImgs.splice(index, 1);
      setPhotos(allImgs);
      closeLoading();
      showToast(`Deleted photo successfully!`, "success");
    } catch (error: any) {
      closeLoading();
      showToast(`${error.response.data.message}`, "error");
    }
  };

  useEffect(() => {
    if (dashData) {
      setPreviewImage({
        profile_img: dashData.image,
      });
      getPhotos();
    }
  }, []);

  useEffect(() => {
    if (dashData !== null) {
      initVenueEditValue.typeOfVenue =
        dashData !== null
          ? transformedArray(dashData.venue_type)
          : dashData.venue_type;
      initVenueEditValue.suitableFor =
        dashData !== null
          ? transformedArray(dashData.suitableFor)
          : dashData.suitableFor;
      initVenueEditValue.amenities =
        dashData !== null
          ? transformedArray(dashData.amenities)
          : dashData.amenities;
    }
  }, []);

  return (
    <Card
      x-chunk="dashboard-05-chunk-3"
      className={`${viewType === "add" ? "border-none shadow-none" : ""}`}
    >
      <CardHeader className="p-0">
        <CardTitle>
          {viewType === "add" ? "Add Venue" : "Venue Settings"}
        </CardTitle>
        <CardDescription>
          {viewType === "add"
            ? "Fill the following fields. Click submit to save venue."
            : "Make changes to your venue here."}
        </CardDescription>
      </CardHeader>
      <CardContent
        className={`p-0 ${
          viewType === "add" ? "max-h-[75vh] overflow-y-scroll pe-2" : ""
        }`}
      >
        <Tabs defaultValue="basic">
          <TabsList
            className={`grid w-full mb-7 ${
              viewType !== "add" ? "grid-cols-2 md:grid-cols-4" : "grid-cols-1"
            }`}
          >
            <TabsTrigger value="basic">Basic Information</TabsTrigger>
            {viewType !== "add" ? (
              <>
                <TabsTrigger value="photos">Photos & Socials</TabsTrigger>
                <TabsTrigger value="contact">Contact </TabsTrigger>
                <TabsTrigger value="working">Working Days</TabsTrigger>
              </>
            ) : null}
          </TabsList>

          <TabsContent value="basic">
            <Formik
              validationSchema={mainSchema}
              onSubmit={(values) => {
                handleSubmit(values, "default");
              }}
              initialValues={initValues}
            >
              {({
                values,
                errors,
                handleChange,
                touched,
                setFieldValue,
                handleSubmit,
              }: any) => (
                <form onSubmit={handleSubmit}>
                  <div>
                    <div className="mb-4">
                      <div className="flex flex-col space-y-1.5">
                        <Label htmlFor="name">Name of Venue</Label>
                        <Input
                          id="name"
                          type="text"
                          placeholder="OutFun Event Center"
                          onChange={handleChange}
                          name="name"
                          value={values.name}
                        />
                        {errors.name && touched.name && (
                          <span className="text-sm font-light text-red-500">
                            {errors.name}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="grid gap-4 mb-4 sm:grid-cols-2 mt-4">
                      <div className="flex flex-col space-y-1.5">
                        <Label htmlFor="typeOfVenue">Type of Venue</Label>
                        <Select
                          closeMenuOnSelect={false}
                          isMulti
                          classNames={{ ...multiSelecClasses }}
                          options={VENUE_TYPES}
                          isSearchable={true}
                          defaultValue={typeOfVenue}
                          onChange={(e: any) => {
                            setFieldValue("typeOfVenue", e);
                            setTypeOfVenue(e);
                          }}
                        />
                        {errors.typeOfVenue && touched.typeOfVenue && (
                          <span className="text-sm font-light text-red-500">
                            {errors.typeOfVenue}
                          </span>
                        )}
                      </div>
                      <div className="flex flex-col space-y-1.5">
                        <Label htmlFor="suitableFor">Suitable for</Label>
                        <Select
                          closeMenuOnSelect={false}
                          isMulti
                          classNames={{ ...multiSelecClasses }}
                          options={SUITABLE_FOR}
                          isSearchable={true}
                          defaultValue={suitableFor}
                          onChange={(e: any) => {
                            setFieldValue("suitableFor", e);
                            setSuitableFor(e);
                          }}
                        />
                        {errors.suitableFor && touched.suitableFor && (
                          <span className="text-sm font-light text-red-500">
                            {errors.suitableFor}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="grid gap-4 mb-4 sm:grid-cols-2">
                      <div className="flex flex-col space-y-1.5">
                        <Label htmlFor="amenities">Amenities</Label>
                        <Select
                          closeMenuOnSelect={false}
                          isMulti
                          classNames={{ ...multiSelecClasses }}
                          options={AMENITIES}
                          isSearchable={true}
                          defaultValue={amenities}
                          onChange={(e: any) => {
                            setFieldValue("amenities", e);
                            setAmenities(e);
                          }}
                        />
                        {errors.amenities && touched.amenities && (
                          <span className="text-sm font-light text-red-500">
                            {errors.amenities}
                          </span>
                        )}
                      </div>
                      <div className="flex flex-col space-y-1.5">
                        <Label htmlFor="capacity">Capacity</Label>
                        <Input
                          id="capacity"
                          type="number"
                          placeholder="200"
                          onChange={handleChange}
                          name="capacity"
                          value={values.capacity}
                        />
                        {errors.capacity && touched.capacity && (
                          <span className="text-sm font-light text-red-500">
                            {errors.capacity}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="flex flex-col space-y-1.5">
                        <Label htmlFor="bio">Description</Label>
                        <Textarea
                          placeholder="Type about your  here."
                          className="resize-none"
                          onChange={handleChange}
                          name="bio"
                          value={values.bio}
                        />
                        {errors.bio && touched.bio && (
                          <span className="text-sm font-light text-red-500">
                            {errors.bio}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="text-center mt-4">
                      <Button className="bg-black" type="submit">
                        Submit
                      </Button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </TabsContent>

          {viewType !== "add" ? (
            <>
              <TabsContent value="photos">
                <div>
                  <div className="flex justify-between items-start">
                    <div>
                      <Label htmlFor="maxtickets">Logo</Label>
                      <p className="text-muted-foreground text-sm mb-3">
                        Upload logo of the venue
                      </p>
                    </div>
                    {/* <Button className="bg-black">
                            <Image /> {""}{" "}
                            <span className="ms-2">Upload Logo</span>
                          </Button> */}
                    <Button
                      className={`bg-black hover:bg-black hover:text-white ${
                        previewImage.profile_img !== null &&
                        "bg-red-700 text-white"
                      }`}
                      onClick={
                        previewImage.profile_img === null
                          ? () => chooseImg("profile_img")
                          : () => delImage("profile_img")
                      }
                    >
                      {previewImage.profile_img === null ? (
                        <>
                          <ImageIcon size={13} className="me-1" />
                          Change image
                        </>
                      ) : (
                        <>
                          Delete image
                          <Trash2Icon size={13} className="me-1" />
                        </>
                      )}
                    </Button>
                  </div>
                  <div className="w-24 h-24 rounded-full mb-7">
                    <img
                      src={
                        previewImage.profile_img !== null
                          ? previewImage.profile_img.url
                          : ppholder
                      }
                      alt="profile logo"
                      className="w-full h-full object-cover rounded-full"
                    />
                  </div>
                  <div className="flex justify-between items-start">
                    <div>
                      <Label htmlFor="maxtickets">Venue Photos</Label>
                      <p className="text-muted-foreground text-sm mb-3">
                        Upload four (4) pictures of the venue
                      </p>
                    </div>
                    <Button
                      className="bg-black"
                      disabled={photos.length < 4 ? false : true}
                      onClick={() => chooseImg("photos")}
                    >
                      <Image /> {""} <span className="ms-2">Upload Photos</span>
                    </Button>
                  </div>
                  <div className="grid grid-cols-5 gap-4">
                    <>
                      {photos.length !== 0 ? (
                        <>
                          {photos.map((image: any, index: any) => (
                            <div
                              className="w-36 h-36 rounded-lg mb-7"
                              key={image}
                            >
                              <img
                                src={image.url || ppholder}
                                alt=""
                                className="w-full h-full object-cover rounded-lg"
                              />
                              <div
                                className="flex justify-center  bg-slate-100 text-red-600 text-sm p-2 mt-2 rounded-md"
                                onClick={() => delPhotos(index)}
                              >
                                <Trash2 size={15} />
                              </div>
                            </div>
                          ))}
                        </>
                      ) : (
                        <div className="col-span-5">
                          <EmptyContainer
                            message="No photos yet."
                            instructions="Click on the upload button to add photos."
                          />
                        </div>
                      )}
                    </>
                  </div>
                  <Formik
                    validationSchema={socialSchema}
                    onSubmit={(values) => {
                      handleSubmit(values, "extra");
                    }}
                    initialValues={initSocialValues}
                  >
                    {({
                      values,
                      errors,
                      handleChange,
                      touched,
                      handleSubmit,
                    }: any) => (
                      <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-2 gap-4 mt-10">
                          <div className="space-y-1">
                            <Label htmlFor="facebook">Facebook Link</Label>
                            <Input
                              id="facebook"
                              className="focus-visible:outline-0 focus:outline-none text-md focus-visible:ring-0 text-sm font-light resize-none"
                              placeholder="https://web.facebook.com/outfunapp"
                              name="facebook"
                              onChange={handleChange}
                              value={values.facebook}
                            />
                            {errors.facebook && touched.facebook && (
                              <span className="text-sm font-light text-red-500">
                                {errors.facebook}
                              </span>
                            )}
                          </div>
                          <div className="space-y-1">
                            <Label htmlFor="instagram">Instagram Link</Label>
                            <Input
                              id="instagram"
                              className="focus-visible:outline-0 focus:outline-none text-md focus-visible:ring-0 text-sm font-light resize-none"
                              placeholder="https://instagram.com/outfunapp"
                              name="instagram"
                              onChange={handleChange}
                              value={values.instagram}
                            />
                            {errors.instagram && touched.instagram && (
                              <span className="text-sm font-light text-red-500">
                                {errors.instagram}
                              </span>
                            )}
                          </div>
                          <div className="space-y-1">
                            <Label htmlFor="twitter">Twitter Link</Label>
                            <Input
                              id="twitter"
                              className="focus-visible:outline-0 focus:outline-none text-md focus-visible:ring-0 text-sm font-light resize-none"
                              placeholder="https://twitter.com/outfunapp"
                              name="twitter"
                              onChange={handleChange}
                              value={values.twitter}
                            />
                            {errors.twitter && touched.twitter && (
                              <span className="text-sm font-light text-red-500">
                                {errors.twitter}
                              </span>
                            )}
                          </div>
                          <div className="space-y-1">
                            <Label htmlFor="youtube">Youtube Link</Label>
                            <Input
                              id="youtube"
                              className="focus-visible:outline-0 focus:outline-none text-md focus-visible:ring-0 text-sm font-light resize-none"
                              placeholder="https://youtube.com/outfunapp"
                              name="youtube"
                              onChange={handleChange}
                              value={values.youtube}
                            />
                            {errors.youtube && touched.youtube && (
                              <span className="text-sm font-light text-red-500">
                                {errors.youtube}
                              </span>
                            )}
                          </div>
                          <div className="space-y-1">
                            <Label htmlFor="linkedin">LinkedIn Link</Label>
                            <Input
                              id="linkedin"
                              className="focus-visible:outline-0 focus:outline-none text-md focus-visible:ring-0 text-sm font-light resize-none"
                              placeholder="https://linkedin.com/outfunapp"
                              name="linkedin"
                              onChange={handleChange}
                              value={values.linkedin}
                            />
                            {errors.linkedin && touched.linkedin && (
                              <span className="text-sm font-light text-red-500">
                                {errors.linkedin}
                              </span>
                            )}
                          </div>
                          <div className="space-y-1">
                            <Label htmlFor="tiktok">Tiktok Link</Label>
                            <Input
                              id="tiktok"
                              className="focus-visible:outline-0 focus:outline-none text-md focus-visible:ring-0 text-sm font-light resize-none"
                              placeholder="https://tiktok.com/outfunapp"
                              name="tiktok"
                              onChange={handleChange}
                              value={values.tiktok}
                            />
                            {errors.tiktok && touched.tiktok && (
                              <span className="text-sm font-light text-red-500">
                                {errors.tiktok}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="text-center mt-4">
                          <Button className="bg-black">Submit</Button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </TabsContent>
              <TabsContent value="contact">
                <Formik
                  validationSchema={contactSchema}
                  onSubmit={(values) => {
                    handleSubmit(values, "extra");
                  }}
                  initialValues={initContactValues}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    touched,
                    setFieldValue,
                    handleSubmit,
                  }: any) => (
                    <form onSubmit={handleSubmit}>
                      <div className="space-y-1 mb-4">
                        <Label htmlFor="address">Street address</Label>
                        <Input
                          id="address"
                          className="focus-visible:outline-0 focus:outline-none text-md focus-visible:ring-0 text-sm font-light resize-none"
                          placeholder="Old Road Street"
                          name="address"
                          onChange={handleChange}
                          value={values.address}
                        />
                        {errors.address && touched.address && (
                          <span className="text-sm font-light text-red-500">
                            {errors.address}
                          </span>
                        )}
                      </div>
                      <div className="grid grid-cols-2 gap-4 mb-4">
                        <div className="space-y-1">
                          <Label htmlFor="city">City</Label>
                          <Input
                            id="city"
                            className="focus-visible:outline-0 focus:outline-none text-md focus-visible:ring-0 text-sm font-light resize-none"
                            placeholder="Accra"
                            name="city"
                            onChange={handleChange}
                            value={values.city}
                          />
                          {errors.city && touched.city && (
                            <span className="text-sm font-light text-red-500">
                              {errors.city}
                            </span>
                          )}
                        </div>
                        <div>
                          <Label htmlFor="country">Country</Label>
                          <MainSelect
                            name="country"
                            onValueChange={(e) => {
                              setFieldValue("country", e);
                            }}
                            value={values.country}
                          >
                            <SelectTrigger
                              className="w-full focus:ring-0 mt-1"
                              id="country"
                            >
                              <SelectValue
                                className="focus:ring-0 font-normal"
                                placeholder="Select country"
                              />
                            </SelectTrigger>
                            <SelectContent className="h-36">
                              <SelectGroup>
                                {mainCountries.map((country) => (
                                  <SelectItem
                                    value={country.name}
                                    key={country.name}
                                  >
                                    {country.name} {country.flag}
                                  </SelectItem>
                                ))}
                              </SelectGroup>
                            </SelectContent>
                          </MainSelect>
                        </div>
                      </div>
                      <div className="grid md:grid-cols-2 gap-4 mb-4">
                        <div className="space-y-1">
                          <Label htmlFor="website">Website</Label>
                          <Input
                            id="website"
                            className="focus-visible:outline-0 focus:outline-none text-md focus-visible:ring-0 text-sm font-light resize-none"
                            placeholder="https://example.com"
                            name="website"
                            onChange={handleChange}
                            value={values.website}
                          />
                          {errors.website && touched.website && (
                            <span className="text-sm font-light text-red-500">
                              {errors.website}
                            </span>
                          )}
                        </div>
                        <div className="space-y-1">
                          <Label htmlFor="email">Email</Label>
                          <Input
                            id="email"
                            className="focus-visible:outline-0 focus:outline-none text-md focus-visible:ring-0 text-sm font-light resize-none"
                            placeholder="example@email.com"
                            name="email"
                            onChange={handleChange}
                            value={values.email}
                          />
                          {errors.email && touched.email && (
                            <span className="text-sm font-light text-red-500">
                              {errors.email}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="grid grid-cols-2 gap-4 mb-4">
                        <div className="space-y-1">
                          <Label htmlFor="phone_one">Phone number One</Label>
                          <Input
                            id="phone_one"
                            className="focus-visible:outline-0 focus:outline-none text-md focus-visible:ring-0 text-sm font-light resize-none"
                            placeholder="+233545001001"
                            name="phone_one"
                            onChange={handleChange}
                            value={values.phone_one}
                          />
                          {errors.phone_one && touched.phone_one && (
                            <span className="text-sm font-light text-red-500">
                              {errors.phone_one}
                            </span>
                          )}
                        </div>
                        <div className="space-y-1">
                          <Label htmlFor="phone_two">Phone number Two</Label>
                          <Input
                            id="phone_two"
                            className="focus-visible:outline-0 focus:outline-none text-md focus-visible:ring-0 text-sm font-light resize-none"
                            placeholder="+233545001001"
                            name="phone_two"
                            onChange={handleChange}
                            value={values.phone_two}
                          />
                          {errors.phone_two && touched.phone_two && (
                            <span className="text-sm font-light text-red-500">
                              {errors.phone_two}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="space-y-1">
                        <Label htmlFor="venue_link">
                          Venue Link (copied from Google Maps)
                        </Label>
                        <Input
                          id="venue_link"
                          className="focus-visible:outline-0 focus:outline-none text-md focus-visible:ring-0 text-sm font-light resize-none"
                          placeholder="https://maps.app.goo.gl/8bzjYFZhe97Ln87"
                          name="venue_link"
                          onChange={handleChange}
                          value={values.venue_link}
                        />
                        {errors.venue_link && touched.venue_link && (
                          <span className="text-sm font-light text-red-500">
                            {errors.venue_link}
                          </span>
                        )}
                      </div>
                      <div className="text-center mt-4">
                        <Button className="bg-black" type="submit">
                          Submit
                        </Button>
                      </div>
                    </form>
                  )}
                </Formik>
              </TabsContent>
              <TabsContent value="working">
                <div className="grid md:grid-cols-2 gap-4">
                  <WHourPicker
                    allDays={allDays}
                    setDayFunc={setAllDays}
                    checked={true}
                    day="Monday"
                  />
                  <WHourPicker
                    allDays={allDays}
                    setDayFunc={setAllDays}
                    checked={true}
                    day="Tuesday"
                  />
                  <WHourPicker
                    allDays={allDays}
                    setDayFunc={setAllDays}
                    checked={true}
                    day="Wednesday"
                  />
                  <WHourPicker
                    allDays={allDays}
                    setDayFunc={setAllDays}
                    checked={true}
                    day="Thursday"
                  />
                  <WHourPicker
                    allDays={allDays}
                    setDayFunc={setAllDays}
                    checked={true}
                    day="Friday"
                  />
                  <WHourPicker
                    allDays={allDays}
                    setDayFunc={setAllDays}
                    checked={true}
                    day="Saturday"
                  />
                  <WHourPicker
                    allDays={allDays}
                    setDayFunc={setAllDays}
                    checked={true}
                    day="Sunday"
                  />
                </div>
                <div className="text-center mt-4">
                  <Button
                    className="bg-black"
                    onClick={() =>
                      handleSubmit({ work_days: allDays }, "extra")
                    }
                  >
                    Submit
                  </Button>
                </div>
              </TabsContent>
            </>
          ) : null}
        </Tabs>
      </CardContent>
    </Card>
  );
};

export default AddEditVenue;
