import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { authImg, ppholder } from "constants/assets";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import {
  Calendar,
  Facebook,
  Instagram,
  Linkedin,
  MapPin,
  Twitter,
  Youtube,
} from "lucide-react";
import moment from "moment";
import { LandingHeader } from "pages/components";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { formatUrl, getCurrencyByCountryName } from "services/misc";

declare global {
  interface Window {
    MSStream?: any;
    opera?: any;
  }
}

const EventDetails = () => {
  const navigate = useNavigate();
  const { name, id } = useParams();
  const axiosPrivate = useAxiosPrivate();
  const [event, setEvent] = useState<any>({});
  const [organizer, setOrganizer] = useState<any>({});
  const [tickets, setTickets] = useState<any>({});

  const socialLinks = [
    {
      name: "facebook",
      icon: Facebook,
      link: "https://web.facebook.com/outfunapp/",
    },
    {
      name: "instagram",
      icon: Instagram,
      link: "https://instagram.com/outfunapp",
    },
    {
      name: "linkedin",
      icon: Linkedin,
      link: "https://gh.linkedin.com/company/outfun",
    },
    { name: "twitter", icon: Twitter, link: "https://twitter.com/outfunapp" },
    { name: "youtube", icon: Youtube, link: "https://instagram.com/outfunapp" },
  ];

  const getEvent = async () => {
    try {
      const event = await axiosPrivate.get(`/events/${id}`);
      const responseData = event.data.data;
      setEvent(responseData);
      if (responseData.name !== undefined) {
        getOrganizer(responseData.owner);
        getTickets(id);
      }
    } catch (error: any) {
      navigate("*");
    }
  };

  const getOrganizer = async (ownerId: string) => {
    try {
      const organizer = await axiosPrivate.get(`/events/organizer/${ownerId}`);
      const responseData = organizer.data.data;
      setOrganizer(responseData);
    } catch (error: any) {}
  };
  const getTickets = async (eventId: any) => {
    try {
      const ticketRes = await axiosPrivate.get(`/events/tickets/${eventId}`);
      const responseData = ticketRes.data.data;
      setTickets(responseData);
    } catch (error: any) {}
  };

  const getPlatform = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      return "android";
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "ios";
    }
    return "web";
  };

  const downloadApp = () => {
    const platform = getPlatform();
    if (platform === "android") {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.ofapp";
    } else if (platform === "ios") {
      window.location.href = "https://apps.apple.com/us/app/outfun/1623470663";
    } else {
      alert("App download available only for Android and iOS devices.");
    }
  };

  useEffect(() => {
    if (id !== undefined) {
      getEvent();
    }
  }, [id]);

  return (
    <div className="flex flex-col md:h-screen">
      <Helmet>
        {/* Open Graph Metadata */}
        <meta property="og:title" content={event?.name} />
        <meta property="og:description" content={event?.desc} />
        <meta
          property="og:image"
          content={
            event?.image !== undefined
              ? event.image.url
              : "https://i.ibb.co/4jSQDXF/newoflogobg.png"
          }
        />
        <meta
          property="og:url"
          content={formatUrl(
            `https://outfun.app/events/${event?.name}/${event?._id}`
          )}
        />
        <meta property="og:type" content="website" />

        {/* Twitter Metadata */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={event?.name} />
        <meta name="twitter:description" content={event?.desc} />
        <meta
          name="twitter:image"
          content={
            event?.image !== undefined
              ? event.image.url
              : "https://i.ibb.co/4jSQDXF/newoflogobg.png"
          }
        />
        <meta
          name="twitter:url"
          content={formatUrl(
            `https://outfun.app/events/${event?.name}/${event?._id}`
          )}
        />
      </Helmet>
      <div className="md:sticky md:top-0">
        <LandingHeader />
        <hr />
      </div>
      <div className="container hide-scrollbar mx-auto px-4 max-w-5xl mt-5 md:flex-1 overflow-y-auto">
        <div className="md:grid grid-cols-3 gap-4">
          <div className="p-4 pb-0 md:pb-4">
            <img
              src={
                event.image !== undefined && event.image !== null
                  ? event.image.url
                  : ppholder
              }
              className="w-full object-cover h-80 rounded-xl"
              alt=""
            />
            <h1 className="text-3xl capitalize text-left font-bold  mt-3 md:mt-0">
              {event.name}
            </h1>
          </div>
          <div className="p-4 col-span-2">
            <div className="flex items-center gap-2 text-gray-600 mb-2">
              <Calendar className="w-5 h-5" />
              <span>{moment(event.startDate).format("ddd. Do MMM YYYY")}</span>
            </div>
            <div className="flex items-center gap-2 text-gray-600 mb-4">
              <MapPin className="w-5 h-5" />
              {event !== undefined && event.venue !== undefined ? (
                <span>
                  {event.venue.venue_name}, {event.venue.address}
                  {" \n"}
                  {event.venue.city} {event.venue.country}
                </span>
              ) : null}
            </div>
            <div className="bg-gray-100 p-4 rounded-lg mb-6">
              <h2 className="font-semibold text-gray-800 mb-2">Tickets</h2>
              <div className="md:grid grid-cols-2 gap-2">
                {tickets !== undefined && tickets.length > 0 ? (
                  <>
                    {tickets.map((ticket: any) => (
                      <div className="mb-4">
                        <h6 className="text-sm text-gray-600">{ticket.name}</h6>
                        {ticket.price !== 0 ? (
                          <span className="font-bold text-md md:text-2xl text-black">
                            {getCurrencyByCountryName(event.venue.country)}{" "}
                            {ticket.price}
                          </span>
                        ) : (
                          <span className="font-bold text-md md:text-2xl text-black">
                            Free
                          </span>
                        )}
                      </div>
                    ))}
                  </>
                ) : null}
              </div>
              <Button
                className="w-full font-bold bg-[#d60c0c] hover:bg-black text-white"
                onClick={downloadApp}
              >
                Get Ticket
              </Button>
            </div>
            <div className="mb-6">
              <h2 className="font-semibold text-gray-800 mb-2">About Event</h2>
              <p className="text-gray-600">{event.desc}</p>
            </div>
            {organizer !== undefined ? (
              <div className="flex items-center gap-4 mb-6">
                <img
                  src={
                    organizer.img !== undefined && organizer.img !== null
                      ? organizer.img.url
                      : ppholder
                  }
                  alt="Host avatar"
                  className="rounded-full w-10 h-10 object-cover"
                />
                <div>
                  <h3 className="font-semibold">Organized by</h3>
                  <p className="text-[#d60c0c] capitalize text-sm md:text-md">
                    {organizer.name}
                  </p>
                </div>
              </div>
            ) : null}
            <div className="flex gap-2">
              {event !== undefined && event.tags !== undefined ? (
                <>
                  {event.tags.map((tag: any) => (
                    <Badge variant="secondary">{tag.text}</Badge>
                  ))}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="md:sticky w-full md:bottom-0 mx-auto max-w-screen-xl pb-5">
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between text-center">
          <span className="text-sm text-gray-500 sm:text-center text-center dark:text-gray-400">
            © {new Date().getFullYear()}{" "}
            <Link to="/home" className="hover:underline">
              OutFun
            </Link>
            . All Rights Reserved.
          </span>
          <div className="flex mt-4 space-x-6 justify-center sm:justify-center sm:mt-0">
            {socialLinks.map((link) => (
              <Link
                to={link.link}
                target="_blank"
                className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
                key={link.name}
              >
                <link.icon />
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventDetails;
