import { useEffect, useRef, useState } from "react";
import {
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Card, Label } from "flowbite-react";
import { Switch } from "@/components/ui/switch";
import AddEditEvent from "pages/components/AddEditEvent";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useUIMisc from "hooks/useUIMisc";
import { useDispatch, useSelector } from "react-redux";
import { setDashboardData } from "redux/features/dashboardSlice";
import QRCode from "qrcode";
import {
  SelectTrigger,
  SelectValue,
  Select as MainSelect,
  SelectContent,
  SelectItem,
  SelectGroup,
} from "@/components/ui/select";
import { CopyIcon, ExternalLink } from "lucide-react";
import { Button } from "@/components/ui/button";
import { formatUrl } from "services/misc";

type Props = {};

const EventSettings = (props: Props) => {
  const { showToast, closeLoading, showLoading } = useUIMisc();
  const axiosPrivate = useAxiosPrivate();
  const { accountData, dashData } = useSelector(
    (state: any) => state.dashboard
  );
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const downloadLinkRef = useRef<HTMLAnchorElement>(null);
  const eventLink = formatUrl(
    `https://outfun.app/events/${dashData?.name}/${dashData?._id}`
  );
  const dispatch = useDispatch();
  const [toogleBool, setToogleBool] = useState(dashData.event_published);
  const [status, setStatus] = useState(dashData.status);
  const toggleSwitch = (e: any) => {
    updateStatus({ event_published: !toogleBool });
    setToogleBool(!toogleBool);
  };

  const updateStatus = async (dataBody: any) => {
    showLoading();
    try {
      const eventUpdate = await axiosPrivate.put(
        `/events/manager/${accountData._id}/${dashData._id}`,
        dataBody
      );
      closeLoading();
      dispatch(setDashboardData(eventUpdate.data.data));
      showToast("Event published updated successfully!", "success");
    } catch (error: any) {
      closeLoading();
      showToast(`${error.response.data.message}`, "error");
    }
  };

  const execLinkFunc = (action: string) => {
    if (action === "copy") {
      copyToClipboard();
    } else if (action === "open") {
      openInNewTab();
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(eventLink);
    showToast(`Link copied`, "success");
  };

  const openInNewTab = () => {
    const newWindow = window.open(eventLink, "_blank");
    if (newWindow) {
      newWindow.focus();
    } else {
      console.error("Failed to open the link in a new tab");
    }
  };

  const handleDownload = () => {
    if (downloadLinkRef.current) {
      downloadLinkRef.current.click();
    }
  };

  useEffect(() => {
    // Generate QR Code
    QRCode.toDataURL(eventLink, { width: 300, margin: 1 })
      .then((url: any) => setQrCodeUrl(url))
      .catch((err: any) => console.error("QR Code generation failed:", err));
  }, [eventLink]);

  return (
    <div className="grid flex-1 items-start gap-4 sm:py-0 md:gap-8 lg:grid-cols-3 xl:grid-cols-3">
      <div className="grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-2">
        <AddEditEvent viewType="settings" />
      </div>
      <div>
        <Card x-chunk="dashboard-05-chunk-3" className="mb-3">
          <CardHeader className="p-0">
            <CardTitle>Additional Settings</CardTitle>
            <CardDescription>Extra actions on your event.</CardDescription>
          </CardHeader>
          <CardContent className="p-0">
            <div className="flex justify-between items-center">
              <h6 className="text-md font-normal capitalize">Published</h6>
              <div>
                <div className="flex items-center space-x-2">
                  <Switch
                    id="status_toggle"
                    checked={toogleBool}
                    onCheckedChange={toggleSwitch}
                  />
                  <Label htmlFor="status_toggle">Live</Label>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
        <Card x-chunk="dashboard-05-chunk-3">
          <CardHeader className="p-0">
            <CardTitle>Event Status</CardTitle>
            <CardDescription>
              Change the main status of your event.
            </CardDescription>
          </CardHeader>
          <CardContent className="p-0">
            <div className="flex flex-col space-y-1.5">
              <Label htmlFor="status">Status</Label>
              <MainSelect
                name="status"
                onValueChange={(e) => {
                  updateStatus({ status: e });
                  setStatus(e);
                }}
                value={status}
              >
                <SelectTrigger className="w-full focus:ring-0 mt-1" id="status">
                  <SelectValue
                    className="focus:ring-0 font-normal"
                    placeholder="Select"
                  />
                </SelectTrigger>
                <SelectContent className="h-36">
                  <SelectGroup>
                    <SelectItem value="active">Live</SelectItem>
                    <SelectItem value="ended">Ended</SelectItem>
                    <SelectItem value="postponed">Postponed</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </MainSelect>
            </div>
          </CardContent>
        </Card>
        <Card x-chunk="dashboard-05-chunk-3">
          <CardHeader className="p-0">
            <CardTitle>Share Event</CardTitle>
            <CardDescription>Copy link or share qr code.</CardDescription>
          </CardHeader>
          <CardContent className="p-0">
            <div>
              <div className="flex items-center gap-3">
                <input
                  type="link"
                  id="website-admin"
                  disabled
                  className="rounded-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={eventLink}
                  placeholder="link"
                />
                <CopyIcon size={20} onClick={() => execLinkFunc("copy")} />
                <ExternalLink size={20} onClick={() => execLinkFunc("open")} />
              </div>
              <div>
                {qrCodeUrl ? (
                  <>
                    <img
                      src={qrCodeUrl}
                      alt="QR Code"
                      className="w-24 h-24 mt-2"
                    />
                    <br />
                    <a
                      ref={downloadLinkRef}
                      href={qrCodeUrl}
                      download={`${dashData?.name}.png`}
                      style={{ display: "none" }}
                    >
                      Download
                    </a>
                    <Button onClick={handleDownload}>Download QR Code</Button>
                  </>
                ) : (
                  <p>Generating QR Code...</p>
                )}
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default EventSettings;
